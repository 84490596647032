.datatable-wrapper.no-header .datatable-container {
  border-top: 1px solid #d9d9d9;
}

.datatable-wrapper.no-footer .datatable-container {
  border-bottom: 1px solid #d9d9d9;
}

.datatable-top,
.datatable-bottom {
  padding: 8px 10px;
}

.datatable-top > nav:first-child,
.datatable-top > div:first-child,
.datatable-bottom > nav:first-child,
.datatable-bottom > div:first-child {
  float: left;
}

.datatable-top > nav:last-child,
.datatable-top > div:last-child,
.datatable-bottom > nav:last-child,
.datatable-bottom > div:last-child {
  float: right;
}

.datatable-selector {
  padding: 6px;
}

.datatable-input {
  padding: 6px 12px;
}

.datatable-info {
  margin: 7px 0;
}

/* PAGER */
.datatable-pagination ul {
  margin: 0;
  padding-left: 0;
}

.datatable-pagination li {
  list-style: none;
  float: left;
}

.datatable-pagination li.datatable-hidden {
  visibility: hidden;
}

.datatable-pagination a {
  border: 1px solid transparent;
  float: left;
  margin-left: 2px;
  padding: 6px 12px;
  position: relative;
  text-decoration: none;
  color: #333;
  cursor: pointer;
}

.datatable-pagination a:hover {
  background-color: #d9d9d9;
}

.datatable-pagination .datatable-active a,
.datatable-pagination .datatable-active a:focus,
.datatable-pagination .datatable-active a:hover {
  background-color: #d9d9d9;
  cursor: default;
}

.datatable-pagination .datatable-ellipsis a,
.datatable-pagination .datatable-disabled a,
.datatable-pagination .datatable-disabled a:focus,
.datatable-pagination .datatable-disabled a:hover {
  pointer-events: none;
  cursor: default;
}

.datatable-pagination .datatable-disabled a,
.datatable-pagination .datatable-disabled a:focus,
.datatable-pagination .datatable-disabled a:hover {
  cursor: not-allowed;
  opacity: 0.4;
}

.datatable-pagination .datatable-pagination a {
  font-weight: bold;
}

/* TABLE */
.datatable-table {
  max-width: 100%;
  width: 100%;
  border-spacing: 0;
  border-collapse: separate;
}

.datatable-table > tbody > tr > td,
.datatable-table > tbody > tr > th,
.datatable-table > tfoot > tr > td,
.datatable-table > tfoot > tr > th,
.datatable-table > thead > tr > td,
.datatable-table > thead > tr > th {
  vertical-align: top;
  padding: 8px 10px;
}

.datatable-table > thead > tr > th {
  vertical-align: bottom;
  text-align: left;
  border-bottom: 1px solid #d9d9d9;
}

.datatable-table > tfoot > tr > th {
  vertical-align: bottom;
  text-align: left;
  border-top: 1px solid #d9d9d9;
}

.datatable-table th {
  vertical-align: bottom;
  text-align: left;
}

.datatable-table th a {
  text-decoration: none;
  color: inherit;
}

.datatable-sorter,
.datatable-filter {
  display: inline-block;
  height: 100%;
  position: relative;
  width: 100%;
}

.datatable-sorter::before,
.datatable-sorter::after {
  content: "";
  height: 0;
  width: 0;
  position: absolute;
  right: 4px;
  border-left: 4px solid transparent;
  border-right: 4px solid transparent;
  opacity: 0.2;
}

.datatable-sorter::before {
  border-top: 4px solid #000;
  bottom: 0px;
}

.datatable-sorter::after {
  border-bottom: 4px solid #000;
  border-top: 4px solid transparent;
  top: 0px;
}

.datatable-ascending .datatable-sorter::after,
.datatable-descending .datatable-sorter::before,
.datatable-ascending .datatable-filter::after,
.datatable-descending .datatable-filter::before {
  opacity: 0.6;
}

.datatable-filter::before {
  content: "";
  position: absolute;
  right: 4px;
  opacity: 0.2;
  width: 0;
  height: 0;
  border-left: 7px solid transparent;
  border-right: 7px solid transparent;
  border-radius: 50%;
  border-top: 10px solid #000;
  top: 25%;
}

.datatable-filter-active .datatable-filter::before {
  opacity: 0.6;
}

.datatable-empty {
  text-align: center;
}

.datatable-top::after,
.datatable-bottom::after {
  clear: both;
  content: " ";
  display: table;
}

table.datatable-table:focus tr.datatable-cursor > td:first-child {
  border-left: 3px blue solid;
}

table.datatable-table:focus {
  outline: solid 1px black;
  outline-offset: -1px;
}
